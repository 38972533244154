import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import PaperWrapper from '../page_wrapper';
import UserProfile from '../User'; 



function HomePage() {
    const navigate = useNavigate();
    const username = UserProfile.getName();

    return (
        <PaperWrapper
            onlyAdmin={false}
        >
            <Grid
                container
                direction={'column'}
                alignItems="center"
                rowSpacing={10}
            >
                <Grid
                    sx={{
                        margin: '10px'
                    }}
                    item
                >
                    <h2>DIVASH | Store Management</h2>
                </Grid>
                <Grid
                    sx={{
                        margin: '10px'
                    }}
                    item
                >
                    <i>Welcome {username}</i>
                </Grid>
                <Grid
                    sx={{
                        margin: '10px'
                    }}
                    item
                >
                    <Button variant="contained" sx={{minWidth:'100px'}} onClick={(e) => {
                        navigate("/inventory/add");
                    }}>
                        Fill Inventory
                    </Button>
                </Grid>
                {UserProfile.isManager() ? (<Grid
                    sx={{
                        margin: '10px'
                    }}
                    item
                >
                    <Button variant="contained" sx={{ minWidth: '100px' }} onClick={(e) => {
                        navigate("/inventory/view")
                    }}>
                        View Inventory
                    </Button>
                </Grid>): <></>}
                <Grid
                    sx={{
                        margin: '10px'
                    }}
                    item
                >
                    <Button variant="contained" sx={{ minWidth: '100px' }} onClick={(e) => {
                        navigate("/bill/add")
                    }}>
                        Add Bill
                    </Button>
                </Grid>
                <Grid
                    sx={{
                        margin: '10px'
                    }}
                    item
                >
                    <Button variant="contained" sx={{ minWidth: '100px' }} onClick={(e) => {
                        navigate("/bill/view")
                    }}>
                        View Bills
                    </Button>
                </Grid>

            </Grid>
        </PaperWrapper>
    )
}

export default HomePage;