import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';


import PaperWrapper from '../page_wrapper';
import { API_ENDPOINT } from '../../App';
import axios from '../../utils/webcall';
import UserProfile from '../User';
import { GreenTickIcon, RedCrossIcon } from '../commons/icons';


function ViewInventoryDetails() {
    const [inventoryData, setInvetoryData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success")
    const [snackBarMessage, setSnackBarMessage] = useState("")
    const [disableButton, setDisableButton] = useState(false);
    const [deletingModal, setDeletingModal] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const navigate = useNavigate();
    const username = UserProfile.getName();
    let inventory_id = window.location.pathname.split("/");
    inventory_id = inventory_id[inventory_id.length - 1];

    const showErrorMessage = (message) => {
        setSnackBarMessage(message);
        setAlertSeverity("error");
        setShowSnackBar(true);
    }

    const showSuccessMessage = (message) => {
        setSnackBarMessage(message);
        setAlertSeverity("success");
        setShowSnackBar(true);
    }

    const loadInventryDetails = () => {
        setLoading(true);
        setDisableButton(true);
        axios.get(`${API_ENDPOINT}/pos/inventory/view/${inventory_id}`).then((res) => {
            let data = res.data;
            data['inventory_details']['state_updates'] = JSON.parse(data['inventory_details']['state_updates'])
            setInvetoryData(data['inventory_details']);
            console.log(data);
        }).catch((error) => {
            console.log(error);
            showErrorMessage(error.message);
        }).finally(() => {
            setLoading(false);
            setDisableButton(false);
        });
    }

    useEffect(() => {
        loadInventryDetails();
    }, []);

    return (
        <PaperWrapper>
            <Snackbar
                open={showSnackBar}
                autoHideDuration={30000}
                onClose={(e) => {
                    setShowSnackBar(false);
                }}
            >
                <Alert
                    onClose={(e) => {
                        setShowSnackBar(false);
                    }}
                    variant="filled"
                    severity={alertSeverity}
                >
                    {snackBarMessage}
                </Alert>
            </Snackbar>
            <Modal
                open={deletingModal}
                onClose={(e) => { }}
            >
                <CircularProgress />
            </Modal>

            <Grid
                container
                direction={'column'}
                rowSpacing={2}
                alignItems="center"
            >
                <Grid item>
                    <h2>DIVASH | ADMIN</h2>
                </Grid>
                <Grid item>
                    <i>Welcome {username}</i>.
                </Grid>
                <Grid item>
                    <b>View Inventory Details.</b>
                </Grid>

                {loading ? <Grid item><CircularProgress /></Grid> : <></>}
                {!loading ? (
                    <>
                        <Grid item>
                            <TableContainer
                                component={Paper}
                                style={{
                                    overflowX: "initial"
                                }}
                                key="inventory-table-container"
                            >
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Item Name</TableCell>
                                            <TableCell align="center">Current Inventory</TableCell>
                                            <TableCell align="center">Last Inventory Date</TableCell>
                                            <TableCell align="center">Is Perishable</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center">{inventoryData['item_name']}</TableCell>
                                            <TableCell align="center">{inventoryData['current_inventory']} {inventoryData['measurement_unit']}</TableCell>
                                            <TableCell align="center">{inventoryData['last_inventory_date']}</TableCell>
                                            <TableCell align="center">{inventoryData['is_perishable'] ? <GreenTickIcon /> : <RedCrossIcon />}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid 
                            item
                            sx={{
                                margin:'20px'
                            }}
                        >
                            <b><i>Inventory Update History</i></b>
                        </Grid>
                        <Grid item>
                            <TableContainer
                                component={Paper}
                                style={{
                                    overflowX: "initial"
                                }}
                                key="inventory-table-container"
                            >
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Update Time</TableCell>
                                            <TableCell align="center">Update Type</TableCell>
                                            <TableCell align="center">Value</TableCell>
                                            <TableCell align="center">User</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.keys(inventoryData['state_updates']).toReversed().map((item, index) => {
                                            console.log(item);
                                            return (
                                                <TableRow>
                                                    <TableCell align="center">{inventoryData['state_updates'][item]['update_time']}</TableCell>
                                                    <TableCell align="center">{inventoryData['state_updates'][item]['type']}</TableCell>
                                                    <TableCell align="center">{inventoryData['state_updates'][item]['value']} {inventoryData['state_updates'][item]['measurement_unit']}</TableCell>
                                                    <TableCell align="center">{inventoryData['state_updates'][item]['user']}</TableCell>
                                                </TableRow>
                                            );
                                        })}


                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </>
                ) : <></>}

            </Grid>
        </PaperWrapper >
    )
}

export default ViewInventoryDetails;