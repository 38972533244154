var UserProfile = (function () {
    var username = "username";
    var auth_key = "cur_user_auth";
    
    var getName = function () {
        return window.sessionStorage.getItem(username);
    };

    var setName = function (name) {
        window.sessionStorage.setItem(username, name);
    };

    var setAuth = function(username, pass, role){
        setName(username);
        window.sessionStorage.setItem(auth_key, JSON.stringify({
            "username": username,
            // "password": pass,
            "role": role.toUpperCase()
        }));
    }

    var getAuth = function (){
        return window.sessionStorage.getItem(auth_key);
    }

    var isLoggedIn = function(){
        let temp = window.sessionStorage.getItem(auth_key);
        console.log(temp);
        return !(temp === null || temp === undefined);
    }

    var logOut = function(){
        window.sessionStorage.removeItem(auth_key);
    }

    var setAccessToken = function(token){
        window.sessionStorage.setItem('access_token', token);
    }

    var getAccessToken = function(){
        return `Bearer ${window.sessionStorage.getItem('access_token')}`;
    }

    var getRole = function(){
        if(!isLoggedIn()){
            return '';
        }
        let temp = JSON.parse(window.sessionStorage.getItem(auth_key));
        console.log("temp", temp);
        return temp['role']
    }

    var isManager = function(){
        console.log("role",getRole());
        return ((getRole() === "MANAGER") || (getRole() === "SUPERUSER"));
    }

    return {
        getName: getName,
        getAuth: getAuth,
        setAuth: setAuth,
        isLoggedIn: isLoggedIn,
        logOut: logOut,
        setAccessToken: setAccessToken,
        getAccessToken: getAccessToken,
        isManager: isManager,
        getRole: getRole
    }

})();

export default UserProfile;