
import red_cross from "../../resources/red_cross.png";
import green_tick from "../../resources/green_tick.png";
import divash_logo from "../../resources/divash_logo.png";
import Box from '@mui/material/Box';
import { green } from "@mui/material/colors";
import { SvgIcon } from "@mui/material";


function RedCrossIcon() {
    return (
        <Box
            component="img"
            sx={{
                height: 20,
                width: 20,
                maxHeight: { xs: 233, md: 167 },
                maxWidth: { xs: 350, md: 250 },
            }}
            alt="Red Cross"
            src={red_cross}
        />
    )
}


function GreenTickIcon() {
    return (
        <Box
            component="img"
            sx={{
                height: 20,
                width: 20,
                maxHeight: { xs: 233, md: 167 },
                maxWidth: { xs: 350, md: 250 },
            }}
            alt="Green Tick"
            src={green_tick}
        />
    )
}

function DivashLogoIcon(props) {
    return (
        <SvgIcon {...props}>
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="460.000000pt" height="459.000000pt" viewBox="0 0 460.000000 459.000000"
                preserveAspectRatio="xMidYMid meet">

                <g transform="translate(0.000000,459.000000) scale(0.100000,-0.100000)"
                    fill="#000000" stroke="none">
                    <path d="M840 4395 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0-10 -7 -10 -15z"/>
                    <path d="M3702 2980 l0 -375 19 70 c43 161 49 375 15 545 -32 159 -34 143 -34 -240z"/>
                </g>
            </svg>

        </SvgIcon>
    )
}

export { RedCrossIcon, GreenTickIcon, DivashLogoIcon };