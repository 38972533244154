import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';


import PaperWrapper from '../page_wrapper';
import { API_ENDPOINT } from '../../App';
import axios from '../../utils/webcall';
import UserProfile from '../User';
import { GreenTickIcon, RedCrossIcon } from '../commons/icons';


function ViewBillDetails() {
    const [billData, setBillData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success")
    const [snackBarMessage, setSnackBarMessage] = useState("")
    const [disableButton, setDisableButton] = useState(false);
    const [deletingModal, setDeletingModal] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const navigate = useNavigate();
    const username = UserProfile.getName();
    let bill_id = window.location.pathname.split("/");
    bill_id = bill_id[bill_id.length - 1];

    const showErrorMessage = (message) => {
        setSnackBarMessage(message);
        setAlertSeverity("error");
        setShowSnackBar(true);
    }

    const showSuccessMessage = (message) => {
        setSnackBarMessage(message);
        setAlertSeverity("success");
        setShowSnackBar(true);
    }

    const loadBillDetails = () => {
        setLoading(true);
        axios.get(`${API_ENDPOINT}/pos/bill/details/${bill_id}`).then((res) => {
            let data = res.data;
            data['bills']['json_data'] = JSON.parse(data['bills']['json_data'])
            setBillData(data['bills']);
        }).catch((error) => {
            console.log(error);

        }).finally(() => {
            setLoading(false);
            setDisableButton(false);
        });
    }

    const approveBill = () => {
        setDisableButton(true);
        axios.post(`${API_ENDPOINT}/pos/bill/approve/${bill_id}/`).then((res) => {
            if (res.status == 200) {
                showSuccessMessage("Approved")
            }
            else {
                showErrorMessage(res.response.data);
            }
        }).catch((error) => {
            console.log(error);
            if (error.status === 400) {
                showErrorMessage(error.response.data);
            }
            else {
                showErrorMessage(error.message);
            }

        }).finally(() => {
            loadBillDetails();
        })
    }

    const deleteBill = () => {
        setDeletingModal(true);
        axios.post(`${API_ENDPOINT}/pos/bill/delete/${bill_id}/`).then((res) => {
            if (res.status == 200) {
                navigate("/bill/view");
            }
        }).catch((error) => {
            console.log(error);
            setSnackBarMessage(error.message);
            setShowSnackBar(true);
        }).finally(() => {
            setDeletingModal(false);
        })
    }

    useEffect(() => {
        loadBillDetails();
    }, []);

    return (
        <PaperWrapper
            onlyAdmin={false}
        >
            <Snackbar
                open={showSnackBar}
                autoHideDuration={30000}
                onClose={(e) => {
                    setShowSnackBar(false);
                }}
            >
                <Alert
                    onClose={(e) => {
                        setShowSnackBar(false);
                    }}
                    variant="filled"
                    severity={alertSeverity}
                >
                    {snackBarMessage}
                </Alert>
            </Snackbar>
            <Modal
                open={deletingModal}
                onClose={(e) => { }}
            >
                <CircularProgress />
            </Modal>
            <Modal
                open={modalOpen}
                onClose={(e) => {
                    setModalOpen(false)
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Grid
                        container
                        direction={'column'}
                        rowSpacing={2}
                        alignItems="center"
                    >
                        <Grid item>
                            <Alert severity="warning">
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    Are you sure you want to delete this bill. <br />Once deleted cannot be undone.
                                </Typography>
                            </Alert>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="outlined"
                                color="success"
                                sx={{
                                    margin: '10px'
                                }}
                                onClick={(e) => {
                                    setModalOpen(false);
                                }}
                            >
                                NO
                            </Button>
                            <Button
                                variant="outlined"
                                color="error"
                                sx={{
                                    margin: '10px'
                                }}
                                onClick={(e) => deleteBill()}
                            >
                                YES
                            </Button>

                        </Grid>
                    </Grid>
                </Box>
            </Modal>
            <Grid
                container
                direction={'column'}
                rowSpacing={2}
                alignItems="center"
            >
                <Grid item>
                    <h2>DIVASH | ADMIN</h2>
                </Grid>
                <Grid item>
                    <i>Welcome {username}</i>.
                </Grid>
                <Grid item>
                    <b>View Bill.</b>
                </Grid>
                <Grid item>
                    {UserProfile.isManager() ? (<Button
                        variant="outlined"
                        onClick={(e) => {
                            approveBill()
                        }}
                        disabled={disableButton}
                        sx={{
                            margin: '10px'
                        }}
                    >
                        Approve
                    </Button>) : <></>}
                    {!loading ? (
                        <>
                            {!billData['approved'] ? (
                                <Button
                                    variant="outlined"
                                    onClick={(e) => {
                                        setModalOpen(true);
                                    }}
                                    disabled={disableButton}
                                    sx={{
                                        margin: '10px'
                                    }}
                                >
                                    Delete Bill
                                </Button>) : <></>}
                        </>
                    ) : <></>}
                </Grid>
                <Grid item>
                    {loading ? <Grid item><CircularProgress /></Grid> : <></>}
                    {!loading ? (<TableContainer
                        component={Paper}
                        style={{
                            overflowX: "initial"
                        }}
                        key="inventory-table-container"
                    >
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Bill #</TableCell>
                                    <TableCell align="center">Approved</TableCell>
                                    <TableCell align="center">Bill Submitted On</TableCell>
                                    <TableCell align="center">Total Amount</TableCell>
                                    <TableCell align="center">Number of Items</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableCell align="center">{bill_id}</TableCell>
                                <TableCell align="center">{billData["approved"] ? <GreenTickIcon /> : <RedCrossIcon />}</TableCell>
                                <TableCell align="center">{billData["submitted_date"]}</TableCell>
                                <TableCell align="center">{billData["total_amount"]} {billData["currency"]}</TableCell>
                                <TableCell align="center">{billData["number_of_items"]}</TableCell>
                            </TableBody>
                        </Table>
                    </TableContainer>) : <></>}
                </Grid>
                {!loading ? (
                    <>
                        <Grid item>
                            <b>Bill Details</b>
                        </Grid>
                        <Grid item>
                            <TableContainer
                                component={Paper}
                                style={{
                                    overflowX: "initial"
                                }}
                                key="bill-details-table-container"
                            >
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Item Name</TableCell>
                                            <TableCell align="center">Quantity</TableCell>
                                            <TableCell align="center">Total Price</TableCell>
                                            <TableCell align="center">Price Per Unit</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {billData['json_data']['data'].map((item, index) => {
                                            return (
                                                <>
                                                    <TableCell align="center">{item["item_name"]}</TableCell>
                                                    <TableCell align="center">{item["quantity"]} {item["measurement_unit"]}</TableCell>
                                                    <TableCell align="center">{item["total_price"]} {billData["currency"]}</TableCell>
                                                    <TableCell align="center">{parseFloat(item["total_price"]) / parseFloat(item["quantity"])} per {item["measurement_unit"]}</TableCell>
                                                </>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </>
                ) : <></>}
            </Grid>
        </PaperWrapper >
    )
}

export default ViewBillDetails;