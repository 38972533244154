import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid2';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import { useNavigate } from "react-router-dom";


import './css/login_page.css';
import { API_ENDPOINT } from '../../App';
import axios from '../../utils/webcall';
import PaperWrapper from '../page_wrapper';
import UserProfile from '../User';

function LoginPage() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword,] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    // const handleClickShowPassword = () => setShowPassword((show) => !show);

    // const handleMouseDownPassword = (event) => {
    //     event.preventDefault();
    // };

    // const handleMouseUpPassword = (event) => {
    //     event.preventDefault();
    // };

    useEffect(()=>{
        if(UserProfile.isLoggedIn()){
            navigate("/home");
        }
    }, []);

    const hanldeLoginButtonClick = (e) => {
        e.preventDefault();
        setLoading(true);
        // Simple validation
        let data = {
            'username': username,
            'password': password
        };
        console.log(data);
        axios.post(`${API_ENDPOINT}/auth/login/`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                'Access-Control-Allow-Credentials': 'true'
            }
        }).then((res) => {
            console.log(res);
            if (res.status === 200) {
                let data = res.data;
                console.log(data);
                UserProfile.setAuth(username, password, data['role']);
                UserProfile.setAccessToken(data['access']);
                navigate('/home')
            }
        }).catch((error) => {
            console.log("Error in Login");
            console.log(error);
            if (error['response']['status'] === 401) {
                setError('Invalid Credentials');
            }
            else {
                setError(error['message']);
            }

        }).finally(() => {
            setLoading(false);
        });
    };

    return (
        <PaperWrapper>
            <h2>DIVASH | Store Management</h2>
            <form>
                <Grid
                    container
                    direction={'column'}
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item>
                            <label>Username</label>
                        </Grid>
                        <Grid item>
                            <input
                                type="text"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                placeholder="Enter your username"
                                autoComplete='on'
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={2} sx={{
                            marginTop: 2,
                            marginBottom: 2
                        }}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item>
                            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                        </Grid>
                        <Grid item>
                            <TextField
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                // endAdornment={
                                //     <InputAdornment position="end">
                                //         <IconButton
                                //             aria-label={
                                //                 showPassword ? 'hide the password' : 'display the password'
                                //             }
                                //             onClick={handleClickShowPassword}
                                //             onMouseDown={handleMouseDownPassword}
                                //             onMouseUp={handleMouseUpPassword}
                                //             edge="end"
                                //         >
                                //             {/* {showPassword ? <VisibilityOff /> : <Visibility />} */}
                                //         </IconButton>
                                //     </InputAdornment>
                                // }
                                />
                        </Grid>
                    </Grid>
                    {error && <div className="error">{error}</div>}
                    <Button variant="contained" disabled={loading} onClick={(e) => hanldeLoginButtonClick(e)}>
                        {loading ? <CircularProgress /> : <span>Login</span>}
                    </Button>
                </Grid>

            </form>
        </PaperWrapper>
    );
}

export default LoginPage;
