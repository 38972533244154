import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';

import axios from '../../utils/webcall';
import { API_ENDPOINT } from '../../App';
import PaperWrapper from '../page_wrapper';
import UserProfile from '../User';


function InventoryFormRow(props) {
    const { data, index, update } = props;
    return (
        <TableRow
            key={`inventory-form-row-${index}`}
        >
            <TableCell align="center">{data['item_name']}</TableCell>
            <TableCell>
                <OutlinedInput
                    id={`outlined-adornment-weight-${index}`}
                    endAdornment={<InputAdornment position="end">{data['measurement_unit']}</InputAdornment>}
                    inputProps={{
                        'aria-label': 'weight',
                    }}
                    onChange={(e) => {
                        update(index, e.target.value);
                    }}
                />
            </TableCell>
        </TableRow>
    )
}

function InventoryForm(props) {
    const { data, update } = props;
    if (data === null || data === undefined) {
        return <></>
    }
    if (data.length === 0) {
        return (
            <Paper
                elevation={2}
                sx={{
                    minHeight: 50,
                    padding: 10
                }}
            >
                No Inventory Pending for today.
            </Paper>
        )
    }
    console.log("data", data);
    return (
        <TableContainer
            component={Paper}
            style={{
                overflowX: "initial"
            }}
            key="inventory-table-container"
        >
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Item Name</TableCell>
                        <TableCell align="center">Quantity</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((item, index) => {
                        return (
                            <InventoryFormRow
                                data={item}
                                index={index}
                                update={update}
                            />
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

function AddInventory() {
    const [loading, setLoading] = useState(true);
    const [inventoryData, setInventoryData] = useState(null);
    const username = UserProfile.getName();

    const updateInventoryData = (index, value) => {
        console.log("called");
        let data = inventoryData;
        data[index]['value'] = value;
        setInventoryData([...data]);
    }

    const show = () => {
        return (!(inventoryData === null || inventoryData === undefined) && inventoryData.length > 0);
    }

    const loadInventoryToBeDone = () => {
        setLoading(true);
        // axios.get(`${API_ENDPOINT}/pos/inventory/list/`, {
        //     'headers': {
        //         'Authorization': UserProfile.getAccessToken()
        //     },
        //     'auth': UserProfile.getAuth()
        axios.get(`${API_ENDPOINT}/pos/inventory/list/`, 
        ).then((res) => {
            if (res.status === 200) {
                let data = res.data['inventory_items'];
                setInventoryData(data);
                setLoading(false);
            }
        }).catch((error) => {
            console.log(error);
        });
    }

    useEffect(() => {
        loadInventoryToBeDone();
    }, [])
    return (
        <PaperWrapper
            onlyAdmin={false}
        >
            <Grid
                container
                direction={'column'}
                rowSpacing={2}
                alignItems="center"
            >
                <h2>DIVASH | ADMIN</h2>
                <Grid item>
                    <i>Welcome {username}</i>
                </Grid>
                <Grid item>
                    <i>Inventory to be done Today</i>
                </Grid>
                <Grid item>
                    {show() ? <Button
                        variant="contained"
                        onClick={(e) => {
                            axios.post(`${API_ENDPOINT}/pos/inventory/update/`, inventoryData).then((res) => {
                                if (res.status === 200) {
                                    loadInventoryToBeDone();
                                }
                            }).catch((error) => {
                                console.log(error);
                            })
                        }}
                    >
                        Send
                    </Button> : <></>}
                </Grid>
                {loading ? <Grid item><CircularProgress /></Grid> : <></>}
                {!loading ? <InventoryForm data={inventoryData} update={updateInventoryData} /> : <></>}
            </Grid>
        </PaperWrapper>
    )
}

export default AddInventory;