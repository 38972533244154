import { BrowserRouter, Routes, Route } from "react-router-dom";
import { CookiesProvider } from 'react-cookie';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { useLocation } from 'react-router-dom'


import { config } from './config';
import './App.css';
import LoginPage from './components/login'
import HomePage from "./components/homepage";
import { AddInventory, ViewInventory, ViewInventoryDetails } from "./components/inventory";
import { AddBill, ViewBillsList, ViewBillDetails } from './components/Bills';

export const API_ENDPOINT = config.url.API_ENDPOINT;


const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});




function App() {
  // const loc = useLocation();
  // console.log(loc.pathname);
  // console.log(loc);

  // const temp = () => {
  //   // const loc = useLocation();
  //   console.log(loc.pathname);
  //   console.log(loc);
  // }
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <CookiesProvider defaultSetOptions={{ path: '/' }}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route index element={<LoginPage />} />
            <Route path="/home" element={<HomePage />} />

            <Route path="/inventory/add" element={<AddInventory />} />
            <Route path="/inventory/view" element={<ViewInventory />} />
            <Route path="/inventory/view/details/*" element={<ViewInventoryDetails />} />

            <Route path="/bill/add" element={<AddBill />} />
            <Route path="/bill/view" element={<ViewBillsList />} />
            <Route path="/bill/view/*" element={<ViewBillDetails />} />

            <Route
              path="*"
              element={
                <>
                {/* {let loc = useLocation()} */}
                {console.log(window.location.path)}
                {console.log(window.location)}
                {/* {console.log(loc)}
                {console.log(loc.pathname)} */}
                {/* {temp()} */}
                ToDO
                </>
              }
            />

          </Routes>
        </BrowserRouter >
      </CookiesProvider>
    </ThemeProvider>
  );
}

export default App;
